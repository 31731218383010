import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import styled from "styled-components"
import Testimonial from "./Testimonial"

const CarouselWrapper = styled.div`
	/* border:solid 1px hotpink; */
	display:block;
	max-width:100%;
	display:flex;
	justify-content:center;
	.carousel-root{
		/* border:solid 1px yellow; */
		display:block;
		width:90%;
	}
`;
const TestimonialWrapper = styled.div`
	display:flex;
	min-height:310px;
	margin:20px;
	justify-content:center;
	align-items:center;
`;
const CarouselItem = () => {
	return (
		<CarouselWrapper>
			<Carousel 
				autoPlay 
				showArrows={false} 
				showStatus={false} 
				showThumbs={false}
				dynamicHeight
			>
				<TestimonialWrapper>
					<Testimonial testimonial="Neil was great to work with in creating our dream kitchen and extension for our home, From our initial conversations, Neil quickly realised the style and feel that we were looking for and presented us with a number of excellent ideas for us to consider. Neil helped and walked us though each stage, from concept to build with his knowledge and expertise." author="Mr &amp; Mrs C Bond – Single Storey Extension"/>
				</TestimonialWrapper>
				<TestimonialWrapper>
					<Testimonial testimonial="Neil has worked on several projects for me, including feasibility schemes for a residential development, an extension and internal alterations to an existing dwelling as well as preparing building regulation drawings (including a SAP calc) for a new build dwelling. He was at hand for each stage of the project and offered advice when needed." author="Mrs C Kelly – Various" />
	
				</TestimonialWrapper>
				<TestimonialWrapper>
					<Testimonial testimonial="We would like to say going through the process of obtaining outline planning permission, reserved matters and progressing onto discharge of condition, Neil Box has been a great help. The plans he produced are excellent, he listens to your needs and changes plans as required.  His in-depth knowledge of the planning system enabled him to answer any questions that we had. He has been extremely thorough organising all of the extra surveys and reports that needed to be completed bringing us in touching distance of being able to build our dream home.<br />
					Thank you Neil Box for all your hard work." author="Mr &amp; Mrs Holliday – Residential Estate" />

				</TestimonialWrapper>
				<TestimonialWrapper>
					<Testimonial testimonial="As a wheelchair user, it has been incredibly important for architectural designs to include accessibility features and careful consideration. Neil from Boxas has an excellent understanding of disability regulations and has been able to design a fantastic home that can be enjoyed by everyone, irrespective of anyone’s physical limitations. Neil has always been available, delivered to project timescales and has been a pleasure to work with." author="Will Weston – Single Storey House Extension" />
					
				</TestimonialWrapper>
				<TestimonialWrapper>
					<Testimonial testimonial="Throughout the design process, Neil has explained in simple terms all of the milestones to achieve in a friendly and logical manner. By working collaboratively with us, we have been able to shoot for the stars, whilst remaining, realistic and grounded. Neil has done a terrific job in designing my major house extension in the countryside. I would highly recommend Neil and Boxas!" author="Sally Ord – Single Storey House Extension" />

				</TestimonialWrapper>
				<TestimonialWrapper>
					<Testimonial testimonial="With over two decades worth of architectural experience, Neil brings a great degree of skill to the table which is coupled with humour, humility and a great ability to listen to customer needs. Boxas have fulfilled every aspect of the brief, which included significantly increasing the square metres of property, whilst ensuring it still complements its countryside surroundings. I would wholeheartedly recommend Neil for whatever architectural project you are considering." author="Sophie Mounsdon – Single Storey House Extension" />
				</TestimonialWrapper>

			</Carousel>
		</CarouselWrapper>
	);
};

export default CarouselItem;








 
