import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import CarouselItem from '../components/testimonials/Carousel';
import { TestimonialsTitle } from '../components/titles/Title'
import FadeIn from "../components/FadeIn";

const Wrapper = styled.div`
	display:flex;
	width:100%;
	flex:1 0 auto;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		flex-direction:column
	}
`;
const LeftText = styled.div`
	flex:1;
	padding-right:20px;
	padding-bottom:20px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%
	}
`;

const Title = styled.h1`
	margin-top:0;
	line-height:1.2;
`;
const TestimonialList = styled.ul`
	list-style:none;
	padding:0;
	margin:0;
`;
const Testimonial = styled.li`
	margin-bottom:2rem;
`;
const Author = styled.p`
	font-size:1rem;
	font-style:italic;
`;
const TestimonialsPage = () => (
  <Layout>
    <SEO title="Boxas - Testimonials" />
		<TestimonialsTitle />
		<FadeIn duration="900" delay="1000">
			<CarouselItem />
		</FadeIn>
		
    {/* <Wrapper> */}
			{/* <LeftText>
				<Title>Testimonials</Title>
				<CarouselItem />
				<TestimonialList>


				</TestimonialList>
				



			</LeftText> */}

			
		{/* </Wrapper> */}
  </Layout>
)

export default TestimonialsPage